import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchListings from "../components/SearchListings"
// import { isAuthenticated } from "../utils/Auth"
// import { navigate } from "gatsby"

const ListingsPage = (props) => {
	// isAuthenticated() && navigate('/u/dashboard')

	return (
		<Layout>
			<SEO title="CCO Listings" />
			<SearchListings {...props} />
		</Layout>
	)
}

export default ListingsPage
