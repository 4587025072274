import React from "react"
import { Highlight } from "react-instantsearch-dom"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui icons
// core components
import GridItem from "../Grid/GridItem"
import Card from "../Card/Card"
import CardBody from "../Card/CardBody"
import {
  CloudinaryMediaSigned,
  CloudinaryMediaSingle,
} from "../CloudinaryMedia"

import imagesStyles from "../../assets/jss/material-kit-pro-react/imagesStyles"
import PropTypes from "prop-types"
import { numberToCurrency } from "../../helpers/common"
import { Link } from "gatsby"

const phoneticEngineCount = {
  1: "Single",
  2: "Twin",
  3: "Triple",
  4: "Quad",
  5: "Quint",
  6: "Hex",
}

const style = {
  ...imagesStyles,
  profile: {
    position: "absolute",
    bottom: "-16px",
    right: "12px",
    width: "60px",
    height: "60px",
    border: "3px solid rgba(200, 200, 200, 0.4)",
    borderRadius: "50%",
    objectFit: "scale-down",
    backgroundColor: "white",
  },
  mainImage: {
    height: "180px",
    width: "100%",
    display: "block",
  },
  descriptionBody: {
    height: "180px",
    position: "relative",
  },
  descriptionContent: {
    position: "absolute",
    bottom: "0",
  },
}

const useStyles = makeStyles(style)

export default function HitComponent({ hit }) {
  const classes = useStyles()
  const avatar = hit.relationships.uid.field_user_avatar
  const name = hit.relationships.uid.name
  const profileImage = { pid: avatar, resourceType: "image" }
  const cloud = JSON.parse(hit.field_cloudinary)
  return (
    <GridItem md={3} className="hit">
      <Link to={hit.alias} style={{ width: "100%" }}>
        <Card>
          <div className="pictures-wrapper">
            <CloudinaryMediaSigned
              image={cloud[0]}
              transformations={"q_100,f_auto,h_180,w_294,c_fill,g_auto:boat"}
              alt={name}
              styles={style.mainImage}
            />
            <CloudinaryMediaSingle
              image={profileImage}
              alt={name}
              transformations={"q_100,f_auto,h_100,c_scale"}
              styles={style.profile}
            />
          </div>
          <CardBody style={style.descriptionBody}>
            <h4 className="media-heading">
              <Highlight attribute="title" hit={hit} />
            </h4>
            <HitDescription hit={hit} />
          </CardBody>
        </Card>
      </Link>
    </GridItem>
  )
}

HitComponent.propTypes = {
  hit: PropTypes.object,
}

function HitDescription({ hit }) {
  return (
    <p style={style.descriptionContent}>
      Type: {hit.field_type} <br />
      {hit.field_price > 0 && (
        <>
          {"Asking: " + numberToCurrency(hit.field_price, "$")}
          <br />
        </>
      )}
      Power: {phoneticEngineCount[hit.field_engine_count]}{" "}
      <Highlight attribute="field_engine_brand" hit={hit} />{" "}
      <Highlight attribute="field_engine_model" hit={hit} />
    </p>
  )
}
