import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"
import { navigate } from "@reach/router"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  ClearRefinements,
  SearchBox,
  Pagination,
  Configure,
  connectHits,
  connectNumericMenu,
  connectRefinementList,
} from "react-instantsearch-dom"
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
} from "react-instantsearch-dom-maps"
import withURLSync from "./URLSync"
import HitComponent from "./HitComponent"
import ConnectedRange from "./ConnectedRange"
// Material UI and Kit
import { Paper } from "@material-ui/core"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import Button from "../CustomButtons/Button"
// Misc
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./listings.css"
import { Link } from "gatsby"

// const DEBOUNCE_TIME = 1000
const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

// const urlToSearchState = ({ search }) => qs.parse(search.slice(1))
// const createURL = (state) => `?${qs.stringify(state)}`
const Listings = (props) => (
  <InstantSearch
    searchClient={searchClient}
    indexName="Listings"
    searchState={props.searchState}
    createURL={props.createURL}
    onSearchStateChange={props.onSearchStateChange}
  >
    <Configure aroundLatLngViaIP={false} />
    <Header />
    <Filters />
    <Results />
  </InstantSearch>
)
// const Listings = ({ location }) => {
//   const [searchState, setSearchState] = useState(urlToSearchState(location))
//   const [debouncedSetState, setDebouncedSetState] = useState(null)
//   // console.log(searchState)
//   const onSearchStateChange = (updatedSearchState) => {
//     // console.log(updatedSearchState)
//     clearTimeout(debouncedSetState)
//
//     setDebouncedSetState(
//       setTimeout(() => {
//         navigate(createURL(updatedSearchState))
//       }, DEBOUNCE_TIME)
//     )
//
//     setSearchState(updatedSearchState)
//   }
//
//   return (
//     <InstantSearch
//       searchClient={searchClient}
//       indexName="Listings"
//       searchState={searchState}
//       onSearchStateChange={onSearchStateChange}
//       createURL={createURL}
//     >
//       <Configure aroundLatLngViaIP={true} />
//       <Header />
//       <Filters />
//       <Results />
//     </InstantSearch>
//   )
// }

function Header() {
  return (
    <header className="navbar">
      <GridContainer alignItems="center">
        <GridItem xs={2} sm={1}>
          <FontAwesomeIcon
            style={{ marginTop: "14px", float: "right" }}
            color={"lightgrey"}
            size={"2x"}
            icon={["fal", "search"]}
          />
        </GridItem>
        <GridItem xs={10} sm={11}>
          <SearchBox />
        </GridItem>
      </GridContainer>
    </header>
  )
}

function Filters() {
  const [showMore, setShowMore] = useState(false)

  const style = {
    itemSpacing: {
      minHeight: "68px",
      borderBottom: "1px solid lightgrey",
      padding: "4px 0 10px 16px",
      margin: "10px 0",
    },
  }
  return (
    <Fragment>
      <Paper style={{ padding: "20px" }}>
        <GridContainer spacing={5}>
          <GridItem xs={12} sm={6} md={8}>
            <GridContainer spacing={0}>
              <GridItem style={style.itemSpacing}>
                <RefinementListLinks
                  title="Listing Type"
                  attribute="field_condition"
                  operator="or"
                />
              </GridItem>
              <GridItem style={style.itemSpacing}>
                <RangeSlider
                  title="Model Year"
                  attribute="field_year"
                  currency={false}
                />
              </GridItem>
              <GridItem style={style.itemSpacing}>
                <RefinementListLinks
                  title="Manufacturers"
                  attribute="field_manufacturer"
                  limit={10}
                  showMoreLimit={50}
                  showMore={showMore}
                  setShowMore={setShowMore}
                />
              </GridItem>
              <GridItem style={style.itemSpacing}>
                <RefinementListLinks
                  title="Vessel Type"
                  attribute="field_type"
                  operator="or"
                />
              </GridItem>
              <GridItem style={style.itemSpacing}>
                <RangeSlider title="Price" attribute="field_price" currency />
              </GridItem>
            </GridContainer>

            <div className="row">
              <div id="stats" />
            </div>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <GoogleMapsLoader
              apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
              endpoint="https://maps.googleapis.com/maps/api/js?v=weekly"
            >
              {(google) => (
                <GeoSearch google={google} minZoom={2}>
                  {({ hits }) => (
                    <Fragment>
                      {hits.map((hit) => (
                        <Marker key={hit.objectID} hit={hit} />
                      ))}
                      <ClearRefinements
                        className="ClearGeoRefinement"
                        transformItems={(items) =>
                          items.filter((item) => item.id === "boundingBox")
                        }
                        translations={{
                          reset: "Clear the map refinement",
                        }}
                      />
                    </Fragment>
                  )}
                </GeoSearch>
              )}
            </GoogleMapsLoader>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "150px",
              }}
            >
              <span style={{ textAlign: "center" }}>
                Want to be list your boat here for FREE? <br />{" "}
                <Link to={"/u/post/boat"}>Click here to get started</Link>
              </span>
            </div>
          </GridItem>
        </GridContainer>
      </Paper>
    </Fragment>
  )
}

function Capacity() {
  return (
    <GridContainer spacing={0}>
      <GridItem sm={2}>Capacity</GridItem>
      <GridItem sm={4}>
        <CapacitySelector
          attribute="person_capacity"
          items={[
            { label: "1 guest", start: 1, end: 1 },
            { label: "2 guests", start: 2, end: 2 },
            { label: "3 guests", start: 3, end: 3 },
            { label: "4 guests", start: 4, end: 4 },
            { label: "5 guests", start: 5, end: 5 },
            { label: "6 guests", start: 6, end: 6 },
          ]}
        />
      </GridItem>
    </GridContainer>
  )
}

function OptionCapacity({ label, value }) {
  return <option value={value}>{label}</option>
}

OptionCapacity.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

const CapacitySelector = connectNumericMenu(
  ({ items, currentRefinement, refine }) => {
    const selectValue = (e) => refine(e.target.value)
    const options = items.map((item) => (
      <OptionCapacity
        label={item.label}
        value={item.value}
        isSelected={item.isRefined}
        key={item.value}
      />
    ))

    return (
      <div className="capacity-menu-wrapper">
        <select defaultValue={currentRefinement} onChange={selectValue}>
          {options}
        </select>
      </div>
    )
  }
)

const RefinementListLinks = connectRefinementList(
  ({ items, refine, createURL, ...props }) => {
    const { title, setShowMore, showMore } = props
    const hitComponents = items.map((item) => (
      <div className={item.isRefined ? " active" : ""} key={item.label}>
        <a
          className="item"
          href={createURL(item.value)}
          onClick={(e) => {
            e.preventDefault()
            refine(item.value)
          }}
        >
          <span>{item.label}: </span>
          <span className="count">{item.count}</span>
        </a>
      </div>
    ))
    return (
      <GridContainer spacing={0} className="rheostat-container">
        <GridItem sm={2}>{title}</GridItem>
        <GridItem sm={10} className="list-links" id={title}>
          {hitComponents}
          {title === "Manufacturers" && (
            <>
              <br />
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  setShowMore(!showMore)
                }}
                color={"transparent"}
                style={{ marginTop: "8px", color: "#555555" }}
              >
                Show {showMore ? "Less" : "More"}
              </Button>
            </>
          )}
        </GridItem>
      </GridContainer>
    )
  }
)

function RangeSlider(props) {
  const { title, attribute, currency } = props
  return (
    <GridContainer spacing={0} className="rheostat-container">
      <GridItem sm={2}>{title}</GridItem>
      <GridItem sm={10} style={{ paddingRight: "34px" }}>
        <ConnectedRange attribute={attribute} currency={currency} />
      </GridItem>
    </GridContainer>
  )
}

const MyHits = connectHits(({ hits }) => {
  const hs = hits.map((hit) => <HitComponent key={hit.objectID} hit={hit} />)
  return (
    <GridContainer spacing={1} id="hits">
      {hs}
    </GridContainer>
  )
})

function Results() {
  return (
    <GridContainer id="results">
      <GridItem>
        <Pagination />
        <MyHits />
      </GridItem>
      <GridItem>
        <Pagination />
      </GridItem>
    </GridContainer>
  )
}

export default withURLSync(Listings)
